import {createSelector} from 'reselect';

const selectRaw = (state) => state.comunicacionInterna.form;

const selectRecord = createSelector([selectRaw], (raw) => raw.record);

const selectMessageRecord = createSelector([selectRaw], (raw) => raw.messageRecord);

const selectInitLoading = createSelector([selectRaw], (raw) => Boolean(raw.initLoading));

const selectSaveLoading = createSelector([selectRaw], (raw) => Boolean(raw.saveLoading));

const selectUpdateCount = createSelector([selectRaw], (raw) => raw.updateCount);

const comunicacionInternaFormSelectors = {
  selectInitLoading,
  selectSaveLoading,
  selectRecord,
  selectMessageRecord,
  selectUpdateCount,
  selectRaw,
};

export default comunicacionInternaFormSelectors;
