import {i18n} from 'src/i18n';
import ComunicacionInternaService from 'src/modules/comunicacionInterna/comunicacionInternaService';
import Errors from 'src/modules/shared/error/errors';
import {getHistory} from 'src/modules/store';
import Message from 'src/view/shared/message';
import TareaListActions from '../../tarea/list/tareaListActions';
import comunicacionInternaListActions from '../list/comunicacionInternaListActions';

import moment from 'moment-timezone';
import layoutActions from 'src/modules/layout/layoutActions';
import mensajeInternoListActions from 'src/modules/mensajeInterno/list/mensajeInternoListActions';

const prefix = 'COMUNICACIONINTERNA_FORM';

const comunicacionInternaFormActions = {
  MESSAGE_INIT_SUCCESS: `${prefix}_MESSAGE_INIT_SUCCESS`,
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  CREATE_STARTED: `${prefix}_CREATE_STARTED`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  CREATE_ERROR: `${prefix}_CREATE_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  doMessageInit:
    (id, row: any = undefined) =>
    async (dispatch) => {
      try {
        dispatch({
          type: mensajeInternoListActions.CLEAR_ROWS,
        });
        // dispatch({
        //   type: comunicacionInternaFormActions.INIT_STARTED,
        // });

        let messageRecord: any = {};

        const isEdit = Boolean(id);

        if (isEdit) {
          messageRecord = await ComunicacionInternaService.find(id);
        } else {
          if (row) {
            const participantesKeys = ['createdBy', 'aceptadores', 'validadores', 'ejecutor', 'encargados'];
            // esos keys pueden ser o array o singular, y pueden ser el id directo o un objeto con el id
            const participantesId = participantesKeys
              .map((key) => {
                if (row[key]) {
                  if (Array.isArray(row[key])) {
                    return row[key].map((p) => p.id || p);
                  } else {
                    return row[key].id || row[key];
                  }
                }
                return null;
              })
              .flat()
              .filter((p) => p);
            const tituloAsunto = `${row.titulo} ${row.tituloTarea}`;
            messageRecord = {
              titulo: '',
              asunto: tituloAsunto,
              participantes: participantesId,
              estado: 'Abierta',
              tipo: 'Tarea',
              tareaAsociada: row.id,
              temaComunicacionData: {nombre: 'Chat interno'},
              passProps: {
                id: row.id,
                tipo: 'Tarea',
              },
            };
            messageRecord = await ComunicacionInternaService.create(messageRecord);
          }
        }

        dispatch({
          type: comunicacionInternaFormActions.MESSAGE_INIT_SUCCESS,
          payload: messageRecord,
        });
        if (messageRecord?.id) {
          dispatch(mensajeInternoListActions.doFetch({comunicacionInterna: messageRecord?.id}, {orderBy: 'asc'}));
        }
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: comunicacionInternaFormActions.INIT_ERROR,
        });

        getHistory().push('/comunicacion-interna');
      }
    },
  doInit:
    (id, row: any = undefined) =>
    async (dispatch) => {
      try {
        dispatch({
          type: comunicacionInternaFormActions.INIT_STARTED,
        });

        let record = {};

        const isEdit = Boolean(id);

        if (isEdit) {
          record = await ComunicacionInternaService.find(id);
        } else {
          if (row) {
            const participantesKeys = ['createdBy', 'aceptadores', 'validadores', 'ejecutor', 'encargados'];
            // esos keys pueden ser o array o singular, y pueden ser el id directo o un objeto con el id
            const participantesId = participantesKeys
              .map((key) => {
                if (row[key]) {
                  if (Array.isArray(row[key])) {
                    return row[key].map((p) => p.id || p);
                  } else {
                    return row[key].id || row[key];
                  }
                }
                return null;
              })
              .flat()
              .filter((p) => p);
            const tituloAsunto = `${row.titulo} ${row.tituloTarea}`;
            record = {
              id: null,
              titulo: tituloAsunto,
              asunto: tituloAsunto,
              participantes: participantesId,
              estado: 'Abierta',
              tipo: 'Tarea',
              temaComunicacionData: {nombre: 'Chat interno'},
              passProps: {
                id: row.id,
                tipo: 'Tarea',
              },
            };
            record = await ComunicacionInternaService.create(record);
          }
        }

        dispatch({
          type: comunicacionInternaFormActions.INIT_SUCCESS,
          payload: record,
        });
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: comunicacionInternaFormActions.INIT_ERROR,
        });

        getHistory().push('/comunicacion-interna');
      }
    },

  doCreate: (values) => async (dispatch) => {
    try {
      dispatch({
        type: comunicacionInternaFormActions.CREATE_STARTED,
      });

      const newRecord = await ComunicacionInternaService.create(values);

      dispatch({
        type: comunicacionInternaFormActions.CREATE_SUCCESS,
      });

      Message.success(i18n('entities.comunicacionInterna.create.success'));

      dispatch(comunicacionInternaListActions.doFetchCurrentFilter());

      if (!values.passProps) {
        if (window.innerWidth > 768) {
          getHistory().push('/comunicacion-interna/vista-360-desktop/' + newRecord.id);
        } else {
          getHistory().push('/comunicacion-interna/vista-360-mobile/' + newRecord.id);
        }
      } else {
        dispatch(TareaListActions.doFetchCurrentFilter());

        dispatch(
          layoutActions.doVisibleDrawer({
            drawerId: 'DrawerComunicacionInternaFormPage',
            drawerVisible: false,
            drawerChanged: moment().unix(),
            drawerUpdateComponent: 'PendienteListPage',
          }),
        );
      }
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: comunicacionInternaFormActions.CREATE_ERROR,
      });
    }
  },

  doUpdate: (id, values) => async (dispatch, getState) => {
    try {
      dispatch({
        type: comunicacionInternaFormActions.UPDATE_STARTED,
      });

      const updatedRecord = await ComunicacionInternaService.update(id, values);

      dispatch({
        type: comunicacionInternaFormActions.UPDATE_SUCCESS,
      });

      Message.success(i18n('entities.comunicacionInterna.update.success'));

      dispatch(comunicacionInternaFormActions.doMessageInit(id));
      dispatch(
        layoutActions.doVisibleDrawer({
          drawerId: 'DrawerComunicacionInternaFormPage',
          drawerVisible: false,
          drawerChanged: moment().unix(),
          drawerUpdateComponent: 'PendienteListPage',
        }),
      );

      if (!values.passProps) {
        // if (window.innerWidth > 768) {
        //   getHistory().push('/comunicacion-interna/vista-360-desktop/' + updatedRecord.id);
        // } else {
        //   getHistory().push('/comunicacion-interna/vista-360-mobile/' + updatedRecord.id);
        // }
      } else {
        dispatch(TareaListActions.doFetchCurrentFilter());

        // dispatch(
        //   layoutActions.doVisibleDrawer({
        //     drawerId: 'DrawerComunicacionInternaFormPage',
        //     drawerVisible: false,
        //     drawerChanged: moment().unix(),
        //     drawerUpdateComponent: 'PendienteListPage',
        //   }),
        // );
      }
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: comunicacionInternaFormActions.UPDATE_ERROR,
      });
    }
  },
};

export default comunicacionInternaFormActions;
