import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useRouteMatch} from 'react-router-dom';
import {i18n} from 'src/i18n';
import actions from 'src/modules/comunicacionInterna/form/comunicacionInternaFormActions';
import selectors from 'src/modules/comunicacionInterna/form/comunicacionInternaFormSelectors';
import layoutActions from 'src/modules/layout/layoutActions';
import {getHistory} from 'src/modules/store';
import ComunicacionInternaForm from 'src/view/comunicacionInterna/form/ComunicacionInternaForm';
import ContentWrapper from 'src/view/layout/styles/ContentWrapper';
import Spinner from 'src/view/shared/Spinner';
import PageTitle from 'src/view/shared/styles/PageTitle';

function ComunicacionInternaFormPage(props) {
  const [dispatched, setDispatched] = useState(false);
  const dispatch = useDispatch();
  const match = useRouteMatch();

  const initLoading = useSelector(selectors.selectInitLoading);
  const saveLoading = useSelector(selectors.selectSaveLoading);
  const record = useSelector(selectors.selectRecord);

  const isEditing = Boolean(props.record?.id || match.params.id);
  const title = isEditing ? i18n('entities.comunicacionInterna.edit.title') : i18n('entities.comunicacionInterna.new.title');

  useEffect(() => {
    dispatch(actions.doInit(props.record?.id || match.params.id));
    setDispatched(true);
  }, [dispatch, match.params.id, props.record?.id]);

  const doSubmit = (id, data) => {
    if (isEditing) {
      dispatch(actions.doUpdate(id, data));
    } else {
      dispatch(actions.doCreate(data));
    }
  };

  const closeDrawer = () =>
    dispatch(
      layoutActions.doVisibleDrawer({
        drawerId: 'DrawerComunicacionInternaFormPage',
        drawerVisible: false,
        drawerChanged: moment().unix(),
        drawerUpdateComponent: 'ComunicacionInternaListPage',
      }),
    );

  return (
    <>
      {/* <Breadcrumb
        items={[
          [i18n('dashboard.menu'), '/'],
          [
            i18n('entities.comunicacionInterna.menu'),
            '/comunicacion-interna',
          ],
          [title],
        ]}
      /> */}

      <ContentWrapper>
        <PageTitle>{title}</PageTitle>

        {initLoading && <Spinner />}

        {dispatched && !initLoading && (
          <ComunicacionInternaForm
            passProps={props.passProps}
            drawer={props.drawer}
            saveLoading={saveLoading}
            initLoading={initLoading}
            record={record}
            isEditing={isEditing}
            onSubmit={doSubmit}
            onCancel={() => (props.drawer ? closeDrawer() : getHistory().push('/comunicacion-interna'))}
          />
        )}
      </ContentWrapper>
    </>
  );
}

export default ComunicacionInternaFormPage;
