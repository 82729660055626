const perfilEmpleadoEnumerators = {
  nationality: [
    'Chilean',
    'Chile',
    'Venezuela',
    'Argentina',
    'Perú',
    'Colombia',
    'Ecuador',
    'Alemania',
    'Cuba',
    'Costa Rica',
    'República Dominicana',
    'Bolivia',
    'Portugal',
    'Haití',
    'Paraguay',
    'Italia',
    'Other',
  ],
  idiomas: ['Español', 'Ingles', 'Frances', 'Aleman', 'Chino', 'Japones'],
  gender: ['Unspecified', 'Male', 'Female', 'Other'],
  contractType: ['Unspecified', 'Indefinite', 'FixedTerm', 'Obra o Faena', 'Other'],
  isContractor: ['true', 'false'],
  companiesBlumar: ['Blumar S.A.', 'Pesquera Bahía caldera S.A.', 'Salmones Blumar Magallanes Spa', 'Salmones Blumar S.A.'],
};

export default perfilEmpleadoEnumerators;
