import {AccordionSummary, AvatarGroup, Box, Chip, IconButton, ListItemIcon, Tooltip, Typography} from '@mui/material';
import React, {useState} from 'react';

import {CircularProgress} from '@mui/material';

import Autorenew from '@mui/icons-material/Autorenew';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {styled, useTheme} from '@mui/material/styles';
import moment from 'moment-timezone';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
import {i18n} from 'src/i18n';
import authSelectors from 'src/modules/auth/authSelectors';
import comunicacionInternaSelectors from 'src/modules/comunicacionInterna/comunicacionInternaSelectors';
import destroyActions from 'src/modules/comunicacionInterna/destroy/comunicacionInternaDestroyActions';
import ConfirmModal from 'src/view/shared/modals/ConfirmModal';
import FilesViewItem from 'src/view/shared/view/FilesViewItem';
import ImagesViewItem from 'src/view/shared/view/ImagesViewItem';
import ChipUserView from 'src/view/user/view/ChipUserView';

import {ArrowBack, LockOpenOutlined, LockOutlined} from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import MoreVertTwoToneIcon from '@mui/icons-material/MoreVertTwoTone';
import {Dialog, DialogContent, DialogTitle} from '@mui/material';
import {isArray} from 'lodash';
import {useRouteMatch} from 'react-router-dom';
import comunicacionInternaFormActions from 'src/modules/comunicacionInterna/form/comunicacionInternaFormActions';
import mensajeInternoListActions from 'src/modules/mensajeInterno/list/mensajeInternoListActions';
import mensajeInternoListSelectors from 'src/modules/mensajeInterno/list/mensajeInternoListSelectors';
import DrawerButton from 'src/view/shared/view/DrawerButton';
import ComunicacionInternaFormPage from '../form/ComunicacionInternaFormPage';

const RootWrapper = styled(Box)(
  ({theme}) => `
        @media (min-width: ${theme.breakpoints.values.md}px) {
          display: flex;
          align-items: center;
          justify-content: space-between;
      }
`,
);

const ListItemIconWrapper = styled(ListItemIcon)(
  ({theme}: any) => `
        min-width: 36px;
        color: ${theme.colors.primary.light};
`,
);

const AccordionSummaryWrapper = styled(AccordionSummary)(
  ({theme}: any) => `
        &.Mui-expanded {
          min-height: 48px;
        }

        .MuiAccordionSummary-content.Mui-expanded {
          margin: 12px 0;
        }

        .MuiSvgIcon-root {
          transition: ${theme.transitions.create(['color'])};
        }

        &.MuiButtonBase-root {

          margin-bottom: ${theme.spacing(0.5)};

          &:last-child {
            margin-bottom: 0;
          }

          &.Mui-expanded,
          &:hover {
            background: ${theme.colors.alpha.black[10]};

            .MuiSvgIcon-root {
              color: ${theme.colors.primary.main};
            }
          }
        }
`,
);

function RenderUniqueUsersGroup(props) {
  const {users} = props;

  let uniqueUsers: any = [];

  users.map((itemUser) => {
    if (itemUser) {
      if (isArray(itemUser)) {
        itemUser.map((raw) => (uniqueUsers[raw.id] = raw));
      } else {
        uniqueUsers[itemUser.id] = itemUser;
      }
    }
  });

  return (
    <AvatarGroup>
      {Object.keys(uniqueUsers).map((id, index) => (
        <ChipUserView
          key={index}
          size={30}
          type={'avatar'}
          user={uniqueUsers[id]}
          sx={{
            mx: 1,
          }}
        />
      ))}
    </AvatarGroup>
  );
}

function TopBarContent(props) {
  const {recordComunicacionInterna} = props;
  const [showMoreOptions, setShowMoreOptions] = useState(window.innerWidth > 768 ? true : false);

  const {t} = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();

  const match = useRouteMatch();

  const [loading, setLoading] = useState(false);

  const [mobileOpen, setMobileOpen] = useState(false);
  const [showModalFiles, setShowModalFiles] = useState(false);

  const [recordIdToDestroy, setRecordIdToDestroy] = useState(null);
  const [recordIdToToggleClose, setRecordIdToToggleClose] = useState(null);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [expanded, setExpanded] = useState('section1');

  const handleChange = (section) => (event, isExpanded) => {
    setExpanded(isExpanded ? section : false);
  };

  const hasPermissionToEdit = useSelector(comunicacionInternaSelectors.selectPermissionToEdit);
  const hasPermissionToDestroy = useSelector(comunicacionInternaSelectors.selectPermissionToDestroy);

  const currentUser = useSelector(authSelectors.selectCurrentUser);

  const currentRoles = useSelector(authSelectors.selectRoles);

  const rowsMensajes = useSelector(mensajeInternoListSelectors.selectRows);

  const doOpenDestroyConfirmModal = (id) => {
    setRecordIdToDestroy(id);
  };

  const doCloseDestroyConfirmModal = () => {
    setRecordIdToToggleClose(null);
  };

  const doOpenToggleCloseConfirmModal = (id) => {
    setRecordIdToToggleClose(id);
  };

  const doCloseToggleCloseConfirmModal = () => {
    setRecordIdToToggleClose(null);
  };

  const doDestroy = (id) => {
    doCloseDestroyConfirmModal();

    dispatch(destroyActions.doDestroy(id, history.location?.pathname));
  };

  const doToggleClose = (id) => {
    doCloseToggleCloseConfirmModal();
    dispatch(
      comunicacionInternaFormActions.doUpdate(id, {
        estado: recordComunicacionInterna.estado !== 'Cerrada' ? 'Cerrada' : 'Abierta',
      }),
    );
  };

  const doCloseModalFiles = () => {
    setShowModalFiles(false);
  };

  const refresh = () => {
    setLoading(true);

    dispatch(
      mensajeInternoListActions.doFetch(
        {
          comunicacionInterna: recordComunicacionInterna?.id,
        },
        {
          orderBy: 'asc',
        },
      ),
    );

    setLoading(false);
  };

  if (!recordComunicacionInterna) {
    // || isEmpty(recordComunicacionInterna)) {
    // Abrir el drawer de nueva comunicación setea record en {}
    // Deberíamos usar una variable diferente en ambas vistas
    return <div></div>;
  }

  //console.log('%c⧭ topbar doc y fotos', 'color: #607339', {recordComunicacionInterna, rowsMensajes});
  let allPhotos = recordComunicacionInterna.fotosAdjunta || [];
  rowsMensajes.forEach((mensajeInterno) => {
    allPhotos = [...allPhotos, ...(mensajeInterno.fotosAdjunta || [])];
  });
  let allDocs = recordComunicacionInterna.documentosAdjuntos || [];
  rowsMensajes.forEach((mensajeInterno) => {
    allDocs = [...allDocs, ...(mensajeInterno.documentosAdjuntos || [])];
  });
  return (
    <>
      <RootWrapper>
        {window.innerWidth <= 768 && (
          <IconButton
            style={{
              border: '1px solid black',
              borderRadius: '50%',
              marginBottom: 20,
            }}>
            <ArrowBack onClick={() => (document.referrer == '' ? history.push('comunicacion-interna') : history.goBack())} />
          </IconButton>
        )}

        <Dialog open={showModalFiles} onClose={doCloseModalFiles} maxWidth="md" fullWidth={true}>
          <DialogTitle
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <h2 style={{margin: 0}}>Documentos y Fotos Adjuntos</h2>
            <IconButton onClick={doCloseModalFiles}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Typography variant="subtitle2">Fotos Adjuntas</Typography>
            {allPhotos.length ? (
              <ImagesViewItem value={allPhotos} />
            ) : (
              <div style={{marginBottom: 10}}>No hay fotos adjuntas</div>
            )}

            <Typography variant="subtitle2">Documentos Adjuntos</Typography>
            {allDocs.length ? <FilesViewItem value={allDocs} /> : <div>No hay documentos adjuntos</div>}
          </DialogContent>
        </Dialog>

        <Box sx={{display: {sm: 'flex'}}} alignItems="center">
          <Box sx={{pl: {sm: 1.5}, pt: {xs: 0.5, sm: 0}}}>
            {window.innerWidth <= 768 && (
              <IconButton color="default" onClick={() => setShowMoreOptions(!showMoreOptions)}>
                {!showMoreOptions && <KeyboardArrowDownIcon />}
                {showMoreOptions && <KeyboardArrowUp />}
              </IconButton>
            )}
          </Box>

          {showMoreOptions && recordComunicacionInterna && (
            <Box
              sx={{
                pl: {sm: 1.5},
                pt: {xs: 1.5, sm: 0},
              }}>
              <Typography variant="subtitle2" gutterBottom color="text.primary">
                {recordComunicacionInterna.asunto &&
                  recordComunicacionInterna.asunto.substr(0, 200).charAt(0).toUpperCase() +
                    recordComunicacionInterna.asunto.slice(1)}{' '}
                {recordComunicacionInterna.asunto?.length > 200 ? '...' : null}
              </Typography>
              <Box sx={{pb: 1, display: 'flex', flexDirection: 'row', flexGrow: 1, gap: 4}}>
                <Box>
                  <Typography variant="subtitle1">
                    {recordComunicacionInterna.temaComunicacion?.nombre
                      ? recordComunicacionInterna.temaComunicacion?.nombre
                      : recordComunicacionInterna.temaComunicacion?.label}
                  </Typography>
                  <Typography variant="caption">{moment(recordComunicacionInterna.createdAt).format('DD/MM/YYYY')}</Typography>
                </Box>
                <Box>
                  {recordComunicacionInterna.estado === 'Cerrada' && (
                    <Chip
                      sx={{
                        color: 'white',
                        backgroundColor: 'green',
                        mr: 1,
                      }}
                      size="small"
                      label={recordComunicacionInterna.estado}
                    />
                  )}
                  {recordComunicacionInterna?.tipo !== 'Tarea' && recordComunicacionInterna.nivelPrioridad?.nombre && (
                    <Chip
                      style={{
                        backgroundColor: recordComunicacionInterna.nivelPrioridad?.color?.split('-')[0],
                        color: recordComunicacionInterna.nivelPrioridad?.color?.split('-')[1],
                      }}
                      size="small"
                      label={
                        recordComunicacionInterna.nivelPrioridad?.nombre
                          ? recordComunicacionInterna.nivelPrioridad?.nombre
                          : recordComunicacionInterna.nivelPrioridad?.label
                      }
                    />
                  )}
                </Box>
                <Box>
                  <RenderUniqueUsersGroup
                    users={[
                      recordComunicacionInterna.createdBy,
                      recordComunicacionInterna.participantes,
                      recordComunicacionInterna.administradores,
                    ]}
                  />
                </Box>
              </Box>
            </Box>
          )}
        </Box>

        {showMoreOptions && (
          <Box
            sx={{
              mt: {xs: 3, md: 0},
            }}>
            <IconButton color="default" onClick={() => refresh()}>
              {!loading ? <Autorenew /> : <CircularProgress color="secondary" />}
            </IconButton>

            {((hasPermissionToEdit &&
              ((recordComunicacionInterna.administradores &&
                recordComunicacionInterna.administradores.filter((item) => item.id === currentUser.id).length > 0) ||
                recordComunicacionInterna.createdBy?.id === currentUser.id)) ||
              currentRoles.includes('admin') ||
              currentRoles.includes('administrador')) && (
              <DrawerButton
                drawerId={'DrawerComunicacionInternaFormPage'}
                type={'icon'}
                size="small"
                width={window.innerWidth * 0.55}
                tooltipTitle={i18n('common.edit')}
                buttonTitle={i18n('common.edit')}
                buttonIcon={<EditIcon />}
                component={<ComunicacionInternaFormPage drawer={true} record={recordComunicacionInterna} />}
              />
            )}

            {((hasPermissionToEdit &&
              recordComunicacionInterna.administradores &&
              recordComunicacionInterna.administradores.filter((item) => item.id === currentUser.id).length > 0) ||
              recordComunicacionInterna.createdBy?.id === currentUser.id ||
              currentRoles.includes('admin') ||
              currentRoles.includes('administrador')) && (
              <Tooltip
                followCursor
                title={recordComunicacionInterna.estado !== 'Cerrada' ? i18n('common.close') : i18n('common.open')}>
                <IconButton color="primary" onClick={() => doOpenToggleCloseConfirmModal(recordComunicacionInterna.id)}>
                  {recordComunicacionInterna.estado !== 'Cerrada' ? <LockOutlined /> : <LockOpenOutlined />}
                </IconButton>
              </Tooltip>
            )}

            {((hasPermissionToDestroy &&
              recordComunicacionInterna.administradores &&
              recordComunicacionInterna.administradores.filter((item) => item.id === currentUser.id).length > 0) ||
              recordComunicacionInterna.createdBy?.id === currentUser.id ||
              currentRoles.includes('admin') ||
              currentRoles.includes('administrador')) && (
              <Tooltip followCursor title={i18n('common.destroy')}>
                <IconButton color="primary" onClick={() => doOpenDestroyConfirmModal(recordComunicacionInterna.id)}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}

            <IconButton color="default" onClick={() => setShowModalFiles(!showModalFiles)}>
              <MoreVertTwoToneIcon color="primary" />
            </IconButton>
          </Box>
        )}
      </RootWrapper>

      {recordIdToDestroy && (
        <ConfirmModal
          title={i18n('common.areYouSure')}
          onConfirm={() => doDestroy(recordIdToDestroy)}
          onClose={() => doCloseDestroyConfirmModal()}
          okText={i18n('common.yes')}
          cancelText={i18n('common.no')}
        />
      )}
      {recordIdToToggleClose && (
        <ConfirmModal
          title={i18n('common.areYouSure')}
          onConfirm={() => doToggleClose(recordIdToToggleClose)}
          onClose={() => doCloseToggleCloseConfirmModal()}
          okText={i18n('common.yes')}
          cancelText={i18n('common.no')}
        />
      )}
    </>
  );
}

export default TopBarContent;
