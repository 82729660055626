import actions from 'src/modules/comunicacionInterna/form/comunicacionInternaFormActions';

const initialData = {
  initLoading: false,
  saveLoading: false,
  record: null,
  messageRecord: null,
  updateCount: 0,
};

export default (state = initialData, {type, payload}) => {
  if (type === actions.INIT_STARTED) {
    return {
      ...state,
      record: null,
      initLoading: true,
    };
  }

  // if (type === actions.MESSAGE_INIT_STARTED) {
  //   return {
  //     ...state,
  //     messageRecord: null,
  //     initLoading: true,
  //   };
  // }

  if (type === actions.MESSAGE_INIT_SUCCESS) {
    return {
      ...state,
      messageRecord: payload,
      updateCount: state.updateCount + 1,
      initLoading: false,
    };
  }

  if (type === actions.INIT_SUCCESS) {
    return {
      ...state,
      record: payload,
      initLoading: false,
    };
  }

  if (type === actions.INIT_ERROR) {
    return {
      ...state,
      record: null,
      initLoading: false,
    };
  }

  if (type === actions.CREATE_STARTED) {
    return {
      ...state,
      saveLoading: true,
    };
  }

  if (type === actions.CREATE_SUCCESS) {
    return {
      ...state,
      saveLoading: false,
    };
  }

  if (type === actions.CREATE_ERROR) {
    return {
      ...state,
      saveLoading: false,
    };
  }

  if (type === actions.UPDATE_STARTED) {
    return {
      ...state,
      saveLoading: true,
    };
  }

  if (type === actions.UPDATE_SUCCESS) {
    return {
      ...state,
      saveLoading: false,
    };
  }

  if (type === actions.UPDATE_ERROR) {
    return {
      ...state,
      saveLoading: false,
    };
  }

  return state;
};
